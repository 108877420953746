import React from "react"
import { graphql } from "gatsby"
import { Location } from "@reach/router"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LayoutContent from "../components/Commun/LayoutContent"
import Filter from "../components/Commun/LayoutContent/Filter"
import IconList from "../components/Commun/LayoutContent/IconList"
import OffreCTA from "../components/Commun/LayoutContent/OffreCTA"
import Social from "../components/Commun/LayoutContent/Social"
import Banner from "../components/Commun/Banner"
import Contact from "../components/Commun/Contact"

const EbooksDetail = ({ data, location }) => {
  const prismicData = getPrismicData(data)

  return (
    <Location>
      {({ location }) => (
        <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
          <SEO
            title={prismicData.banner.titre}
            description={prismicData.contenuPrincipal.introduction}
            isProduct
            url={location.href}
            image={data.prismicEbookDetail.data.body[1].primary.photo.url}
            price={prismicData.contenuPrincipal.prix}
          />
          <Banner
            title={prismicData.banner.titre}
            imageBanner={data.ebookDetailsImage.childImageSharp.fluid}
            imageHeight="350px"
            imageMobileHeight="350px"
            isfirstBlock
            marginBottom="0"
          />
          <LayoutContent
            contenu={prismicData.contenuPrincipal}
            noFilter
            buttonText="Acheter"
            buttonUrl="/"
            titreSnipcart={prismicData.banner.titre}
            descriptionSnipcart={
              prismicData.contenuPrincipal.descriptionEcommerce
            }
            prixSnipcart={prismicData.contenuPrincipal.prix}
            idSnipcart={prismicData.contenuPrincipal.id_ecommerce.text}
            urlSnipcart={prismicData.uid}
            statutSnipcart={prismicData.contenuPrincipal.statut}
            promotion={prismicData.contenuPrincipal.promotion}
            statutEbook={prismicData.contenuPrincipal.statut}
            // idFileEbook="de1a9232-bd9e-4138-84f6-e236d5843834"
          >
            <Filter
              location={location}
              items={prismicData.listeEbooks.items}
              prefix="/e-books/"
              currentEbookCategorie={prismicData.contenuPrincipal.categorie}
            />
            <IconList
              icon1={data.clipboard.childImageSharp.fixed}
              icon2={data.timer.childImageSharp.fixed}
              icon3={data.weight.childImageSharp.fixed}
            />
            <OffreCTA
              titre="Mes Coachings"
              text="Découvrez toutes mes prestations de coaching sportif personnalisé pour homme et pour femme à Nantes et à distance"
              buttonText="En savoir plus"
              buttonUrl="/coaching-sportif-personnalise"
            />
            <Social />
          </LayoutContent>
          <Banner
            title={prismicData.accroche.contenu}
            imageBanner={data.accroche.childImageSharp.fluid}
            imageHeight="450px"
            imageMobileHeight="350px"
            marginBottom="0"
          />
          <Contact />
        </Layout>
      )}
    </Location>
  )
}

export default EbooksDetail

export const query = graphql`
  query($uid: String!) {
    prismicEbookDetail(uid: { eq: $uid }) {
      uid
      data {
        body {
          ... on PrismicEbookDetailBodyBanniere {
            primary {
              contenu {
                text
              }
            }
          }
          ... on PrismicEbookDetailBodyContenuPrincipal {
            id
            primary {
              statut
              prix {
                html
                text
              }
              description_ecommerce {
                html
                text
              }
              titre {
                text
              }
              categorie {
                text
              }
              introduction {
                text
              }
              contenu {
                html
              }
              promotion
              promotion_prix {
                text
              }
              id_ecommerce {
                text
              }
              photo {
                url
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              label_promotion {
                text
              }
            }
          }
          ... on PrismicEbookDetailBodyAccroche {
            primary {
              contenu {
                text
              }
            }
          }
        }
      }
    }
    allPrismicEbookDetail {
      edges {
        node {
          uid
          data {
            body {
              ... on PrismicEbookDetailBodyContenuPrincipal {
                primary {
                  contenu {
                    text
                  }
                  titre {
                    text
                  }
                  categorie {
                    text
                  }
                  introduction {
                    text
                  }
                  photo {
                    fluid {
            ...GatsbyPrismicImageFluid
          }
                  }
                }
              }
            }
          }
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ebookDetailsImage: file(relativePath: { eq: "ebook-details.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accroche: file(relativePath: { eq: "accroche.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clipboard: file(relativePath: { eq: "icones/clipboard-bleu.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 25, height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    timer: file(relativePath: { eq: "icones/timer-bleu.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 25, height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    weight: file(relativePath: { eq: "icones/weight-bleu.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 25, height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    uid: data.prismicEbookDetail.uid,
    banner: {
      titre: data.prismicEbookDetail.data.body[0].primary.contenu.text,
    },
    listeEbooks: {
      items: data.allPrismicEbookDetail.edges,
    },
    contenuPrincipal: {
      image:
        data.prismicEbookDetail.data.body[1].primary.photo.fluid,
      titre: data.prismicEbookDetail.data.body[1].primary.titre.text,
      contenu: data.prismicEbookDetail.data.body[1].primary.contenu.html,
      categorie: data.prismicEbookDetail.data.body[1].primary.categorie.text,
      introduction:
        data.prismicEbookDetail.data.body[1].primary.introduction.text,
      prix: data.prismicEbookDetail.data.body[1].primary.prix.text,
      descriptionEcommerce:
        data.prismicEbookDetail.data.body[1].primary.description_ecommerce.text,
      id: data.prismicEbookDetail.data.body[1].id,
      statut: data.prismicEbookDetail.data.body[1].primary.statut,
      id_ecommerce: data.prismicEbookDetail.data.body[1].primary.id_ecommerce,
      promotion: {
        active: data.prismicEbookDetail.data.body[1].primary.promotion,
        prix: data.prismicEbookDetail.data.body[1].primary.promotion_prix.text,
        label:
          data.prismicEbookDetail.data.body[1].primary.label_promotion.text,
      },
    },
    accroche: {
      contenu: data.prismicEbookDetail.data.body[2].primary.contenu.text,
    },
  }
}
