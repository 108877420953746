import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  margin-left: 0;
  background-color: white;
  border-radius: ${globalVariables.borderRadius};
  padding: 2rem;
  height: fit-content;
  margin-top: 2rem;
  a span.btn-text {
    font-size: 16px;
  }
`

const Title = styled.p`
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.headerColor};
  margin-bottom: 1rem;
`
const List = styled.ul`
  margin-left: 0;
`

const Item = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    color: ${props => props.theme.bodyColor};
    padding: 0.5rem 0;
    font-size: 14px;
  }
`

const ImageWrapper = styled.div`
  flex: 0 0 auto;
  margin-right: 1rem;
`

const IconList = ({ icon1, icon2, icon3 }) => {
  return (
    <Wrapper>
      <Title>Contenu des e-books</Title>
      <List>
        <Item>
          <ImageWrapper>
            <Img fixed={icon1} />
          </ImageWrapper>
          <span>Mes meilleures méthodes de développement pour débutants et intermédiaires</span>
        </Item>
        <Item>
          <ImageWrapper>
            <Img fixed={icon2} />
          </ImageWrapper>
          <span>Un gain de temps considérable sur votre progression</span>
        </Item>
        <Item>
          <ImageWrapper>
            <Img fixed={icon3} />
          </ImageWrapper>
          <span>Des astuces simples qui vous permettront de devenir totalement indépendant</span>
        </Item>
      </List>
    </Wrapper>
  )
}

export default IconList
