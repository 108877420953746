import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import globalVariables from "../../globalVariables"

const FilterWrapper = styled.ul`
  margin-left: 0;
  background-color: white;
  border-radius: ${globalVariables.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: fit-content;
  li {
    list-style: none;
  }
  & ul {
    padding-left: 42px;
    li {
      padding-left: 0;
    }
  }
`

const Item = styled.li`
  padding: 1.5rem 2rem 1.5rem 2rem;
  border-right: 3px solid
    ${props => (props.isActive ? props.theme.secondary : props.theme.white)};
  transition: all 0.2s ease-in-out;
  &:hover {
    border-right: 3px solid ${props => props.theme.secondary};
    a {
      font-weight: 700;
    }
  }
  & a {
    font-weight: ${props => (props.isActive ? "700" : "normal")};
  }
`

const ButtonFilter = styled(Link)`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-weight: ${props => (props.isActive ? "700" : "normal")};
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 20px;
  color: ${props => props.theme.headerColor};
`

const Filter = ({ location, prefix, items, currentEbookCategorie, gender }) => {
  return (
    <FilterWrapper>
      {items
        .filter(item =>
          currentEbookCategorie
            ? item.node.data.body[1].primary.categorie.text ===
              currentEbookCategorie
            : true
        )
        .map((item, index) => {
          const link =
            prefix + (currentEbookCategorie ? item.node.uid : item.url)
          return (
            <Item
              key={index}
              isActive={
                link === location.pathname || `${link}/` === location.pathname
              }
            >
              <ButtonFilter to={link} state={{ category: gender }}>
                {currentEbookCategorie
                  ? item.node.data.body[1].primary.titre.text
                  : item.titre.text}
              </ButtonFilter>
            </Item>
          )
        })}
    </FilterWrapper>
  )
}

export default Filter
